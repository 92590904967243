/* styles/Loading.css */
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
